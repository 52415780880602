import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export default class Responsive extends Component {
  render() {
    const settings = {
      dots: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      pauseOnHover: true,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <div className="container section">
        <Slider {...settings}>
          <div>
            <a
              href="https://www.instagram.com/yasi0307"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/di1olybhs/image/upload/c_scale,w_230/v1651126337/ig01_pwdx3k.png"
                alt="ig-img"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/yasi0307"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/di1olybhs/image/upload/c_fill,w_230,h_230/v1700565105/Screen_Shot_2023-11-21_at_7.11.16_PM_nyemgt.png"
                alt="ig-img"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/yasi0307"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/di1olybhs/image/upload/c_fill,h_230,w_230/v1674790553/20221001_214052_szsgzc.jpg"
                alt="ig-img"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/yasi0307"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/di1olybhs/image/upload/c_fill,w_230,h_230/v1700565105/Screen_Shot_2023-11-21_at_7.10.47_PM_iiibqy.png"
                alt="ig-img"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/yasi0307"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/di1olybhs/image/upload/c_scale,w_230/v1651127126/Screen_Shot_2022-04-28_at_2.24.44_PM_rbdoc5.png"
                alt="ig-img"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/yasi0307"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/di1olybhs/image/upload/c_fill,h_230,w_230/v1674791015/277818696_670460534206427_123797490279124349_n_vme0dp.jpg"
                alt="ig-img"
              />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/yasi0307"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://res.cloudinary.com/di1olybhs/image/upload/c_fill,w_230,h_230,e_improve/v1738306768/Screenshot_2025-01-31_at_2.59.01_PM_lgxxzh.png"
                alt="ig-img"
              />
            </a>
          </div>
        </Slider>
      </div>
    );
  }
}
