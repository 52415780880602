import Layout from "../../components/Layout/layout.component";
import "./music.styles.css";

export default function Music() {
  return (
    <Layout>
      <section className="section">
        <div className="banner music-banner">Music</div>
      </section>
      <div className="content-container section-content">
        <iframe
          title="spotify"
          src="https://open.spotify.com/embed/track/21pMEYj0CgUBqwgfQnQ5zt?utm_source=generator"
          width="100%"
          height="352"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
          style={{
            width: "100%",
            maxWidth: "660px",
            overflow: "hidden",
            background: "transparent",
            marginBottom: "18px",
          }}
        ></iframe>

        <iframe
          title="i live on a island"
          allow="autoplay *; encrypted-media *; fullscreen *"
          frameBorder={0}
          height={450}
          style={{
            width: "100%",
            maxWidth: "660px",
            overflow: "hidden",
            background: "transparent",
            marginBottom: "18px",
          }}
          // sandbox='allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation'
          src="https://embed.music.apple.com/us/album/%E6%88%91%E4%BD%8F%E5%9C%A8%E4%B8%80%E5%80%8B%E5%B3%B6%E4%B8%8A-ep/1510924820"
        />

        <div
          className="video"
          style={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 */,
            paddingTop: 25,
            height: 0,
            marginBottom: "18px",
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src="https://www.youtube.com/embed/7nMV73ObHI8"
            frameBorder="0"
            title="米粉姆仔 Bi-Hun-m-a"
          />
        </div>

        <div
          className="video"
          style={{
            position: "relative",
            paddingBottom: "56.25%" /* 16:9 */,
            paddingTop: 25,
            height: 0,
            marginBottom: "18px",
          }}
        >
          <iframe
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            src="https://www.youtube.com/embed/ogKjR9BgeUM"
            frameBorder="0"
            title="STACO - 羹 KENN ft. YASI 張雅淳"
          />
        </div>

        <iframe
          title="裝咖人"
          src="https://open.spotify.com/embed/album/4UvSrXHMiu1auwVDnrQMKL?utm_source=generator"
          width="100%"
          height="352"
          frameBorder="0"
          allowfullscreen=""
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
          loading="lazy"
        ></iframe>
      </div>
    </Layout>
  );
}
