import Aos from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import Layout from "../../components/Layout/layout.component";
import SimpleAccordion from "../../components/accordion/accordion.component";

import "./about.styles.css";

export default function About() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);
  return (
    <Layout>
      <section className="section">
        <div className="banner about-banner">About</div>
      </section>
      <div className="content-container">
        <div className="section-content">
          <h4>張雅淳</h4>
          <h4>Tiunn Ngá-Sûn</h4>
          <p className="about-text">
            曾學習南管、唸歌，師從唸歌國寶楊秀卿。作品以月琴民謠與新創唸歌為主。月琴民謠作品以旅行與生活中的感觸見聞為題材，觀察與體悟人生，化為歌謠。
          </p>
          <p className="about-text">
            2017年為電影《血觀音》主題曲〈滿樹翠碧〉作詞、線上遊戲《打鬼》片尾曲〈星月無邊〉作詞人，作品〈開往花蓮的火車〉入選2019南面而歌、〈偷食魚〉入選公視台語台2023年「咱的歌：台語囡仔歌」。2020年發行首張專輯《我住在一個島上》發行。
          </p>
          <p className="about-text">
            近年來致力於新創唸歌本的創作，希望帶大家一同進入生動感人的唸歌世界。新創唸歌作品有：《老仙婆遊天庭》、《陳年年玄遇記：大稻埕霞海城隍廟篇》、《陳年年玄遇記：新莊地藏庵篇》等。
          </p>
        </div>
        <div
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="0"
          data-aos-offset="-200"
          className="section-content"
        >
          <h4>​張雅淳與朋友列島</h4>
          <h4>Tiunn Ngá-Sûn & Friends Archipelago</h4>
          <p>
            「張雅淳與朋友列島」為張雅淳與鍵盤手與編曲家賴小蘑、吉他手呂進榮、鼓手文河等音樂人好友共同組成，共同嘗試挖掘民謠、創作與聲響的更多創作面貌，在每一個成員的音樂之島中跳躍前進。
          </p>
          <p>
            Singer-songwriter and yueqin player Ya-Chun Chang’s music is rooted
            in Taiwan’s folk tradition. She will play traditional zithers yueqin
            (Taiwanese) and perform her own original composition.{" "}
          </p>
          <p>
            邵大倫〈來相褒〉合唱
            <br />
            測不準工作室〈米粉姆仔〉
            <br /> 2019南面而歌〈開往花蓮的火車〉入選
            <br /> 電影《血觀音》主題曲〈滿樹翠碧〉作詞
            <br /> 線上遊戲《打鬼》片尾曲〈星月無邊〉作詞
            <br /> 公視新創電影《疑霧公堂》宣傳曲〈壽至公堂〉詞曲
            <br /> 法國「待藝Dàiyì」〈滿州三景〉演唱（作曲賴小蘑）
            <br />
            Easy Shen《anatta 如果身體全部開放了》
          </p>
        </div>
        <div
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="0"
          data-aos-offset="-200"
          className="section-content"
        >
          <h4>重點演出經歷</h4>
          <p>
            2019-2024 台灣月琴民謠祭「傳承、香火、世代情」臺灣民謠薪傳音樂會
            <br />
            2021、2024 兩廳院 界址創作《打N檔，不要踩煞車》
            <br />
            2019、2024 大港開唱
            <br />
            2023 神童派對拚場×慈諴宮《狩siù》
            <br />
            2023 靚舞集《島嶼ê色緻kap Me-ló͘-tih 咱sóa kha步lâi走chhōe》
            <br />
            2023 台南言論自由日
            <br />
            2023 落山風藝術季
            <br />
            2022 拚場《勻境》《渡》
            <br />
            2022 臺灣文博會開幕典禮
            <br />
            2022 北美館「掘光而行：洪瑞麟」開幕演出
            <br />
            2021-2023 戲春大稻埕
            <br />
            2021 臺東聲音藝術節
            <br />
            ​2021 拚場《靈蹤》
            <br />
            2020 半島歌謠祭
            <br />
            ​2019 南面而歌音樂會
            <br />
            2019 第十屆總統文化獎頒獎典禮
            <br />
            2019、2022 白晝之夜
            <br />
            2018 掌中戲歌舞劇《海水鹹鹹》
            <br />
            ​2018 臺北藝穗節
            <br />
            2016、2017 日本長野乙事音樂祭
            <br />
            2015 沖繩 Koza Terurin 音樂祭
            <br />
          </p>
        </div>
        <div
          data-aos="fade-zoom-in"
          data-aos-easing="ease-in-back"
          data-aos-delay="0"
          data-aos-offset="-200"
          className="section-content"
        >
          <h4>更多歷年演出經歷 My Tours</h4>
          <SimpleAccordion />
        </div>
      </div>
    </Layout>
  );
}
