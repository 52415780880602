import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function SimpleAccordion() {
  return (
    <div>
      {/* 2025 */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 2 5 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20250103 李育昇電氣神將開光入神儀式謝神演唱@士林慈諴宮
              <br />
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* 2024 */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 2 4 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20241222 月照羅東｜張雅淳冬夜走唱@NiNôo
              <br />
              20241215 曹順和號 — 歲末音樂會@曹家洋樓
              <br />
              20241214 歲末潭美聚@銀髮服務新創活力中心
              <br />
              20241127 夜官暗訪老仙婆: Live演出現場 (CD)簽名會@誠品松菸
              <br />
              20241123-24 花蓮日出藝文基地w/小蘑
              <br />
              20241110 聲擊脈動：錄音+AI科技後製出版發表會@牯嶺街小劇場
              <br />
              20241030 輔仁大學講座
              <br />
              20241012 後壁女人節
              <br />
              20241009 南台科技大學講座
              <br />
              20241005 《唸唸、唱唱｜張雅淳秋走唱》大河壩小書店
              <br />
              20240928 台灣月琴民謠祭
              <br />
              20240921-22 界址創作《打N檔，不要踩煞車》@兩廳院
              <br />
              20240824 藝穗節-靚舞集《海湧號》
              <br />
              20240815 講古走唱人復辟！- 張雅淳&子豪（女王頭）@女巫店
              <br />
              20240606 「她的新電影」專題開幕演唱@國家影視聽中心
              <br />
              20240427 花聲客廳
              <br />
              20240422 南台科技大學講座
              <br />
              20240421 台灣唸歌節
              <br />
              20240330 大港開唱w/裝咖人《夜官暗訪老仙婆》
              <br />
              20240316 日月琴的夜：當三味線與月琴相遇w/Tota
              Imai今井田歌@新竹江山藝改所
              <br />
              20240205 全國古蹟日
              <br />
              20240203 走路草臺南文化中心新春特展《四季美滿》
              <br />
              20240126 秀藝傳歌張雅淳《老仙婆遊天庭》唸歌發表@女巫店
              <br />
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* 2023 */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 2 3 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20231118 高雄戲獅甲 <br />
              20231111 神童派對拚場×慈諴宮《狩siù》 <br />
              20231022 關渡賞鳥博覽會 <br />
              20231007 別所誠洋feat.張雅淳@女巫店 <br />
              20231001 大鹿港市 鹿港上青 <br />
              20230930 北投月琴民謠祭 <br />
              20230923 太平山城藝棧夏日音樂會 <br />
              20230916 全國古蹟日 <br />
              20230909 基隆山海小市集 <br />
              20230715 靚舞集《島嶼ê色緻kap Me-ló͘-tih 咱sóa kha步lâi走chhōe》
              <br />
              20230506-07 戲春大稻埕 <br />
              20230422-23 艋舺舞蹈節 W/靚舞集 <br />
              20230408 台南言論自由日 <br />
              20230311 「第九景（聽其自由）」@嘉義 台灣圖書室 <br />
              20230215 女力拉美加論壇感謝晚宴 <br />
              20230211 世界母語日記者會 <br />
              20230115 島嶼ê色緻 跤步ê旋律創作分享會@樂暢親子共學 <br />
              20230106 別所誠洋&張雅淳【TONKORI與月琴弦話】@女巫店
              <br />
              20230101 落山風藝術季《風湧之南》 <br />
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/* 2022 */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 2 2 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20221224 2022Mattauw大地藝術季 渡拔之夜
              <br />
              20221217 島上怪人們的詩：張雅淳的敘事歌謠講唱會@高雄幻廳
              <br />
              20221119 2022秋潮集 @屏東潮州
              <br />
              20221023 雲林三秀園【薈萃秋市】
              <br />
              20221016 龜山眷村故事館館慶
              <br />
              20221008-09 今秋藝術節@鹿港
              <br />
              20221002 北投月琴民謠祭
              <br />
              20221001 白晝之夜 拚場《渡tōo》
              <br />
              20220918 拚場《勻境ûn-king》@空總 <br />
              20220911 一對相望如臨秋-李臨秋紀念音樂會
              <br />
              20220823 稻埕佰市-大稻埕百年好店選物展 <br />
              20220816 《ICOM 2022布拉格大會》行前記者會 <br />
              20220804 2022臺灣文博會開幕典禮「群島共振RESONANCE ISLAND」
              <br />
              20220730 「第九景（聽其自由）」－陳漢聲個展
              <br />
              20220710 嚴英浩×張雅淳賴小蘑｜假如，我是｜創作共演@一文錢
              <br />
              20220604 青春有時-鳥瞰青春大稻埕特展 @老好大稻埕
              <br />
              20220507-8 戲春大稻埕
              <br />
              20220417 2022春潮集 @屏東潮州
              <br />
              20220413 續舊-2022彩繪修復特展開幕記者會 <br />
              20220401 北投日常風景-民謠常在生活裡
              <br />
              20220319 北美館「掘光而行：洪瑞麟」開幕演出 <br />
              ​20220318 女巫店 張雅淳彈唱會給2022年的你
              <br />
              20220313 拚場《勻境ûn-king》@湯德章紀念公園
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>2 0 2 1 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20211209-1212 界址創作 《打N檔不要踩煞車》
              <br />
              20211102 來客坐
              <br />
              20211030 臺東聲音藝術節
              <br />
              20211016 文協百年 本島音樂會 變裝市集
              <br />
              20211009 地衣荒物 仕女沙龍寫生會——月琴與花道的行為肖像
              <br />
              202107 界址創作《十八號》
              <br />
              20210508、09 戲春大稻埕
              <br />
              2021/4-5 拚場《靈蹤》@臺中歌劇院
              <br />
              20210319 《帶著詩歌在島上旅行Vol.1》W/克萊兒、音速死馬
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 2 0 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20201231 女巫年度票房毒藥集錦跨年聯歡晚宴
              <br />
              20201211 胭脂虎EP發片場 @小地方
              <br />
              20201125 走路草：噥噥時間交換所
              <br />
              20201121 城西生活節
              <br />
              20201117 半島歌謠祭 (張雅淳與朋友列島)
              <br />
              20201114 基隆 浪花天-依海而生的城市展覽 開幕演出
              <br />
              20201113 裝著老派靈魂的少年：飛鴻X雅淳@暢歌
              <br />
              20201002 月琴民謠祭
              <br />
              20200930 小河音樂派對 @86設計公寓
              <br />
              20200926 竹涼祭｜裸市集
              <br />
              20200912 新北投車站 鐵道生活節
              <br />
              20200911 Farewell民生東
              <br />
              20200821 京站情人好聲音
              <br />
              20200808 基隆山海小市集
              <br />
              20200802 楊秀卿唸歌團公演《呂蒙正》@高雄至善廳
              <br />
              20200724 ​臺式浪潮來襲 - 第四輯：我們所珍視的島嶼日常(朋友列島)
              <br />
              20200718-19 台中Love Earth小農市集土地音樂會
              <br />
              20200712 楊秀卿唸歌團公演《呂蒙正》@桃園文化中心
              <br />
              20200711 女巫店
              <br />
              20200704 2020台北霞海城隍文化節
              <br />
              20200403 「濟州4‧3」特展系列活動-「春日療癒」音樂會
              <br />
              20200424 ​房間裡的小旅行─女巫店專場
              <br />
              20200229 磯崎-粗食生活節
              <br />
              20200229 花創藝文櫥窗－草地音樂
              <br />
              20200206 張雅淳 feat.賴小蘑/小朱
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>2 0 1 9 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20191220 雙生仔迷你專輯發表會嘉賓 @女巫店
              <br />
              20191215 南面而歌音樂會 @高雄駁二月光劇場(小蘑、阿榮、文河)
              <br />
              20191213 融合風景-雅淳桑的朋友列島音樂會@一文錢咖啡
              <br />
              20191018 第十屆總統文化獎頒獎典禮演出樂手 <br />
              20191005 白晝之夜
              <br />
              20190928 臺灣月琴民謠祭
              <br />
              20190914 農閒藝術節-大社在這裡
              <br />
              20190816 雅淳桑的朋友列島音樂會
              @女巫店（賴小蘑、賴思安、黃品文、文河）
              <br />
              20190813、20 昇恆昌表演藝術季 @桃園機場 feat.阿泰
              <br />
              20190727 基隆山海小市集
              <br />
              20190620 女巫店 feat.康裘 w/陳智輝
              <br />
              20190616 小室花聲音樂節 feat.康裘
              <br />
              20190615 台北霞海城隍文化節音樂會
              <br />
              20190523 台北霞海城隍文化節記者會
              <br />
              20190517「聚起來！組一支地域振興臺灣隊」地方創生交流小聚
              <br />
              20190512 戲台酒館Illisin Lounge談唱(主持人：CMO樂團蘇瓦那)
              <br />
              20190511《李梅樹紀念館x新樂園藝術空間交流展》開幕演出
              <br />
              20190504 展覽《河川先生的囤物癖》開幕茶會演出
              <br />
              20190501 爛泥發芽前夜祭
              <br />
              20190323 大港開唱 feat.康裘
              <br />
              20190308 發春音樂祭/Blossomy music festival @元智大學
              <br />
              20190302 噶瑪蘭築夢生活市集 @宜蘭中興文創園區
              <br />
              20190209《張雅淳/岡崎 真 makoto okazaki》@女巫店
              <br />
              20190130 《民間傳說故事》w/飛鴻、睡後故事 @Mica雲母
              <br />
              ​20190126 流民棧尾牙音樂祭 w/ 海筆子大樂隊、今井田歌 @流民棧
              <br />
              20190123 TJPO台日產業合作推動辦公室喬遷慶祝活動 w/廣田武豪
              (三味線)
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 1 8 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20181231 淡水曙光祭 feat.巫康裘
              <br />
              20181229 月台：今井田歌與他的民謠朋友們 Platform:Tota Imai and His
              Folk Music Friends @新竹 江山藝改所
              <br />
              20181208
              今井田歌▲燦爛日本▲吟遊詩人的三味線之夜（冬日限定）@燦爛時光：東南亞主題書店
              Brilliant Time bookstore
              <br />
              20181118 掌中戲歌舞劇《海水鹹鹹》@台南北門區三寮灣東隆宮文化中心
              <br />
              20181103 今井田歌《月台》@草御殿
              <br />
              20181013 掌中劇歌舞劇《海水鹹鹹》@霞海城隍廟
              <br />
              20181011-12 掌中劇歌舞劇《海水鹹鹹》花蓮巡迴
              <br />
              20180922 掌中劇歌舞劇《海水鹹鹹》w/靜謐時光、幸運草偶劇團 @女巫店
              <br />
              20180908 來khi廟埕逛市集
              <br />
              20180825 @樂悠悠之口
              <br />
              20180808 《雅淳桑的低氣壓音樂會》臺北藝穗節 藝穗好好玩
              <br />
              20180802 @女巫店
              <br />
              20180428 @櫻木匠
              <br />
              20180420 台灣文博會
              <br />
              20180308 女巫店 春天的唱會
              <br />
              2018217-18 @彰化 成美文化園
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 1 7 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20171126 @海邊的卡夫卡 feat.郭品(tabla)
              <br />
              20171028-29 高雄衛武營藝術祭 feat.巫康裘
              <br />
              20170909 臺北藝穗節藝穗俱樂部-高海拔音樂會 <br />
              20170826 日本長野乙事音樂祭 feat.巫康裘
              <br />
              20170727 女巫店 feat.巫康裘
              <br />
              20170623 @台北月見君想 feat.巫康裘 <br />
              20170512 @台東鐵花村
              <br />
              20170408 今井田歌の三味線之旅 Tota Imai’s Shamisen Journey <br />
              20170401 愚人節陪你樂悠悠feat.巫康裘 w/害喜喜 @樂悠悠之口
              <br />
              20170211 【小雞bb過元宵-來大稻埕遇見好雞會】feat.巫康裘
              @團圓大稻埕
              <br />
              20170205 海邊的卡夫卡 feat.巫康裘
              <br />
              20170203 女巫店 feat.巫康裘
              <br />
              20170114 印度愛樂夜(郭品、祝立群) 來賓 @竹北巴黎艷后
              <br />
              20170107 流變之冬，南境漫遊行旅 River of South
              feat.郭品tabla、祝立群sitar @新竹江山藝改所
              <br />
              20170103【大和気本線】第三回－東風 · 島歌 w/Haruzaki東風(日本)
              @樂悠悠之口
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 1 6 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20161203《穿越北印丟丟銅》即興詩人吟遊之夜
              feat.郭品tabla、祝立群sitar @睡不著咖啡
              <br />
              20161217《人魚與丟丟銅》北印度與台灣融合之夜
              feat.郭品tabla、祝立群star @女巫店
              <br />
              20161029 幸福第三部曲：官菜酒樓酒家菜 w/害喜喜@團圓大稻埕
              <br />
              20161019 月老出差沖繩記者會 @霞海城隍廟
              <br />
              20161016 海山頭老麵攤月琴演唱 @新莊海山里老厝邊麵攤
              <br />
              20161016 三角市集
              <br />
              20161009 臺北詩歌節開幕式
              <br />
              20160930【大和気本線】第二回－津輕 · 島歌 w/武豪、克里夫
              @樂悠悠之口
              <br />
              20160903 When Lonely Street Lights Greet The Room —
              寂靜電子民謠分離與共演：Michael Vincent Manalo (PH) X 張雅淳
              @新竹江山易改所
              <br />
              20160823 夏夜吹起一陣非主流韓風 @Blue Hydrangea
              <br />
              20160830 日本長野乙事祭
              <br />
              20160728 女巫店 feat.郭品
              <br />
              20160724 海邊的卡夫卡 feat.黃培育
              <br />
              20160715 河床劇團《窺》
              <br />
              20160707 花蓮海或瘋市集
              <br />
              20160529 島嶼女聲 w/陳柔米 @宜蘭賣捌所
              <br />
              20160227 書店X時報出版 《村上群像》@板橋 書店
              <br />
              20160214 紅樓市集
              <br />
              20160212 苗．春祭 Sprouts Art Festival
              <br />
              20160107 女巫店
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 1 5 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20151220【三角市集-小怪物森林派對】
              <br />
              20151113 板橋 書店 w/克里夫
              <br />
              20151107-08 Orion Beer Festival沖繩啤酒節
              <br />
              20151105 女巫店
              <br />
              20150814 台東都蘭 好的吧
              <br />
              20150813 莊永明《活！該如此》新書發表會
              <br />
              20150611 女巫店
              <br />
              20150412 沖繩コザ・てるりん祭 koza Terurin Fest
              <br />
              20150319 女巫店
              <br />
              20150227 苗．春祭 Sprouts Art Festival
              <br />
              20150117 沖繩南部之美 宣傳活動
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 1 4 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20141227 典藏咖啡 feat.劉尹樵
              <br />
              20141204 女巫店 w/陳柔米
              <br />
              20141107 We did
              it大稻埕廚房音樂會〈用創作修理世界的共好實驗〉feat.劉尹樵
              <br />
              20141020 Orion Beer Festival沖繩啤酒節
              <br />
              20140926 在彼方 w/柯濟昇 @淡水 海明威
              <br />
              20140920 女巫店
              <br />
              20140823 w/柯濟昇 @台中 艸田空間
              <br />
              20140705 三角市集
              <br />
              2014 淡水重建街市集
              <br />
              20140515 女巫店
              <br />
              20140503 台南 么八二空間 -182artspace
              <br />
              20140502 松菸護樹晚會
              <br />
              20140420 公投盟晚會
              <br />
              2014 尹樵與雅淳 三弦走春巡迴：
              <br />
              20140301思劇場
              <br />
              20140308睡不著咖啡
              <br />
              20140322永樂座 行唱走春音樂會 (溫州公園)
              <br />
              20140323 Re‧貳一咖啡
              <br />
              20140330保安捌肆 Boan 84
              <br />
              20140401穆勒咖啡
              <br />
              20140411 尖蚪咖啡
              <br />
              20140227 女巫店
              <br />
              20140215 On My Way . Taipei Hostel / 途中．台北 青年旅舍
              青年旅館。
              <br />
              20140118 台南福憩客棧
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>2 0 1 3 年</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            <p>
              20131231 女巫店
              <br />
              20131201 海邊的卡夫卡
              <br />
              20131124 台中 奉咖啡
              <br />
              20131101 女巫店
              <br />
              20131026 丹尼一日音樂節
              <br />
              20131006 小麻雀二手店開幕
              <br />
              20130901 台東都蘭 好的吧
              <br />
              20130824 簡單歌唱會
              <br />
              20130804 台中 瑪德雷齊偲拉
              <br />
              20130526 旅人好好窩 w/ Walter Wu
            </p>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
